import React from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { rem } from 'polished';

import bgPattern from 'images/background-pattern.png';
import Form from './Form';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Section = styled.section`
  background: url(${bgPattern}),
    linear-gradient(to bottom, #fff 554px, #f6f8fb 418px);
  background-repeat: no-repeat;
  background-position: top 389px left, center;

  @media (max-width: ${breakpoints.tablet}) {
    background: #fff;
  }
`;

const FlexContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  max-width: 50%;
  padding-right: 107px;

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
    padding: 0;
  }
`;

const SectionTitle = styled.h4`
  text-transform: uppercase;
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.light};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const SectionSubtitle = styled.h3`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  margin-bottom: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
    margin-bottom: 56px;
  }
`;

const SectionDescription = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  opacity: 0.8;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 64px;
  }
`;

const ContactSection = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Box>
        <FlexContainer>
          <TextContainer>
            <SectionTitle>
              {t('contacts.contact_section.title.31')}
            </SectionTitle>
            <SectionSubtitle>
              {t('contacts.contact_section.subtitle')}
            </SectionSubtitle>
            <SectionDescription>
              {t('contacts.contact_section.description')}
            </SectionDescription>
          </TextContainer>
          <Form />
        </FlexContainer>
      </Box>
    </Section>
  );
};

export default ContactSection;

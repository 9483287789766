import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;
  border: 1px solid #dee2e6;
  background-color: transparent;
  border-radius: 50%;

  .checkmark {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    display: inline-block;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  input:checked + .checkmark {
    background-color: #02c4b2;
    opacity: 1;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      border-radius: 50%;
      background-color: #fff;
    }
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 15px;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

interface RadioInputProps {
  id: string;
  name: string;
  children: React.ReactNode;
  required?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput = ({
  id,
  name,
  children,
  required,
  checked,
  onChange,
}: RadioInputProps) => {
  return (
    <Label htmlFor={id}>
      <RadioWrapper>
        <Input
          type="radio"
          name={name}
          id={id}
          required={required}
          onChange={onChange}
          checked={checked}
        />
        <span className="checkmark" />
      </RadioWrapper>
      {children}
    </Label>
  );
};

export default RadioInput;

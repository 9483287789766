import React from 'react';
import type { HeadFC } from 'gatsby';

import PageHero from 'components/PageHero';
import ContactSection from './components/ContactSection';
import OfficesList from './components/OfficesList';
import ContactsList from './components/ContactsList';

const Contact = () => {
  return (
    <>
      <PageHero />
      <ContactSection />
      <OfficesList />
      <ContactsList />
    </>
  );
};

export default Contact;

export const Head: HeadFC = () => <title>Contact</title>;

import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

import RadioInput from 'components/RadioInput';
import CheckboxInput from 'components/CheckboxInput';
import ContactMethods from 'utils/constants/ContactMethods';

import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { PHONE_REGEX } from 'utils/phoneNumberRegex';
import { MAXIMUM_CHARACTERS_TEXT_AREA } from 'utils/constants';
import getPhoneInputLocalization from 'utils/getPhoneInputLocalization';
import { breakpoints } from 'theme';
import sendContactFormEmail from 'services/customer.io/sendContactFormEmail';
import LoadingSpinner from 'components/LoadingSpinner';

const Button = styled.button`
  background-color: ${({ theme }) => theme.brandPalette.primary};
  border: ${({ theme }) => `1px solid ${theme.brandPalette.primary}`};
  color: ${({ theme }) => theme.neutralPalette.white};

  padding: 12px 24px;
  border-radius: 8px;

  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.brandPalette.primary};
    color: ${({ theme }) => theme.brandPalette.primary};
    background-color: transparent;
  }
`;

const StyledForm = styled.form`
  flex: 1;
  max-width: 532px;

  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 40px 40px 56px 40px;

  @media (max-width: ${breakpoints.tablet}) {
    box-shadow: none;
    padding: 0;
  }

  input,
  select,
  textarea {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    padding: 12px 0 12px 16px;

    color: ${(props) => props.theme.brandPalette.dark};
    font-size: ${rem(16)};
    line-height: ${rem(24)};

    transition: all 150ms ease-in-out;

    &::placeholder {
      color: ${(props) => props.theme.neutralPalette.neutral6};
    }
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.brandPalette.primary};
  }

  .phoneInputContainer {
    border: 1px solid #e9ecef;
    width: 100%;

    &:focus {
      border: 1px solid ${(props) => props.theme.brandPalette.primary};
      box-shadow: none;
    }
  }

  .buttonContainer {
    border-right: 1px solid #e9ecef;

    .open::before {
      border: 1px solid ${(props) => props.theme.brandPalette.primary};
      box-shadow: 0 0 0 0.2rem rgba(2, 196, 178, 0.4);
    }

    .arrow {
      border-top: 4px solid #02c4b2;

      &.up {
        border-bottom: 4px solid #02c4b2;
      }
    }
  }
`;

const FormTitle = styled.h3`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(24)};
  line-height: ${rem(34)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};

  margin-bottom: 48px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }
`;

const NameInput = styled.input``;
const EmailInput = styled.input``;

const ContactReasonInput = styled.textarea`
  resize: none;
`;

const FlexRow = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
  margin-bottom: 80px;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
    margin-bottom: 48px;

    &:nth-of-type(3n) {
      flex-direction: row;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 74px;
  gap: 32px;

  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 48px;
  }
`;

const ContactMethodTitle = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  margin-bottom: 30px;
`;

const ContactMethodLabel = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const PrivacyCheckboxLabel = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  &:hover {
    cursor: pointer;
  }

  a {
    color: ${(props) => props.theme.brandPalette.primary};
    font-weight: ${(props) => props.theme.fontWeights.bold};

    transition: all 150ms ease-in-out;

    &:hover {
      color: ${(props) => props.theme.brandPalette.secondary};
    }
  }
`;

const CharCountWrapper = styled.small`
  align-self: flex-end;

  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  margin-top: -24px;
`;

const CurrentCharacters = styled.p`
  display: inline;
  color: ${(props) => props.theme.neutralPalette.neutral7};
`;

const MaximumCharacters = styled.p<{ limitReached?: boolean }>`
  display: inline;
  color: ${({ theme, limitReached }) =>
    limitReached
      ? theme.semanticPalette.error.base
      : theme.neutralPalette.neutral6};
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(14)};
  margin-top: 32px;
`;

const FormSuccessMessage = styled.p`
  color: ${(props) => props.theme.brandPalette.secondary};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(14)};
  margin-top: 32px;
`;

const CONTACT_METHODS = [
  {
    id: ContactMethods.EMAIL,
    label: 'contacts.contact_section.contact_channel.email',
  },
  {
    id: ContactMethods.PHONE,
    label: 'contacts.contact_section.contact_channel.phone',
  },
  {
    id: ContactMethods.WHATSAPP,
    label: 'contacts.contact_section.contact_channel.whatsapp',
  },
];

const CONTACT_SCHEDULES = [
  {
    id: 'morning',
    label: 'contacts.contact_section.contact_period.morning',
  },
  {
    id: 'afternoon',
    label: 'contacts.contact_section.contact_period.afternoon',
  },
];

const Form = () => {
  const [formData, setFormData] = useState<ContactFormDTO>({
    contactMethod: ContactMethods.EMAIL,
  } as ContactFormDTO);
  const [textAreaCurrentCharacterCount, setTextAreaCurrentCharacterCount] =
    useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState<string | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { language, t } = useI18next();

  const phoneInputLanguage = getPhoneInputLocalization(language);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage(null);

    const submissionDestination = 'info@safebrok.com';

    if (!PHONE_REGEX.test(formData.phoneNumber)) {
      return setErrorMessage('Please enter a valid phone number.');
    }

    if (!formData.acceptedPrivacyPolicy) {
      return setErrorMessage('Please read and accept the privacy policy.');
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);
      const res = await sendContactFormEmail(formData, submissionDestination);

      if (!res.ok) {
        throw new Error('Something went wrong, please try again.');
      }

      setFormSuccessMessage(
        'Thank you for your contact, someone will be in touch soon.',
      );
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleContactMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const removeKeyFromObject = (obj: any, keyToNegate: string[]) => {
      return Object.keys(obj)
        .filter((key) => !keyToNegate.includes(key))
        .map((key) => ({ [key]: obj[key] }))
        .reduce((prev, curr) => {
          return {
            ...prev,
            ...curr,
          };
        });
    };

    const newData = removeKeyFromObject(formData, ['contactSchedule']);

    setFormData({
      ...newData,
      contactMethod: e.target.id,
    });
  };

  const countTextAreaCharacters = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const currentValue = e.target.value;

    setTextAreaCurrentCharacterCount(currentValue.length);
    setFormData({ ...formData, contactReason: currentValue });
  };

  const handlePrivacyPolicyCheckbox = (checked: boolean) => {
    setFormData({ ...formData, acceptedPrivacyPolicy: checked });
  };

  return (
    <StyledForm onSubmit={handleFormSubmit}>
      <FormTitle>{t('contacts.contact_section.title')}</FormTitle>
      <FlexColumn>
        <NameInput
          type="text"
          name=""
          id=""
          placeholder={t('contacts.contact_section.name_placeholder')}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          required
        />
        <EmailInput
          type="email"
          name=""
          id=""
          placeholder={t('contacts.contact_section.email_placeholder')}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          required
        />
        <PhoneInput
          placeholder={t('contacts.contact_section.number_placeholder')}
          country={language === 'en' ? 'gb' : language}
          localization={phoneInputLanguage}
          preferredCountries={['es', 'pt', 'it']}
          countryCodeEditable={false}
          onChange={(e) => setFormData({ ...formData, phoneNumber: `+${e}` })}
          inputProps={{
            name: 'phone',
            required: true,
          }}
          containerClass="container"
          inputClass="phoneInputContainer"
          buttonClass="buttonContainer"
        />
        <ContactReasonInput
          name=""
          id=""
          rows={3}
          maxLength={MAXIMUM_CHARACTERS_TEXT_AREA}
          placeholder={t('contacts.contact_section.reason_placeholder')}
          onChange={countTextAreaCharacters}
        />
        <CharCountWrapper>
          <CurrentCharacters>{textAreaCurrentCharacterCount}</CurrentCharacters>
          <MaximumCharacters
            limitReached={
              textAreaCurrentCharacterCount === MAXIMUM_CHARACTERS_TEXT_AREA
            }
          >
            /{MAXIMUM_CHARACTERS_TEXT_AREA}
          </MaximumCharacters>
        </CharCountWrapper>
      </FlexColumn>
      <ContactMethodTitle>
        {t('contacts.contact_section.contact_channel.question')}
      </ContactMethodTitle>
      <FlexRow>
        {CONTACT_METHODS.map((item) => (
          <RadioInput
            id={item.id}
            name="contactMethod"
            onChange={handleContactMethodChange}
            key={item.id}
            checked={formData.contactMethod === item.id}
          >
            <ContactMethodLabel>{t(item.label)}</ContactMethodLabel>
          </RadioInput>
        ))}
      </FlexRow>
      {formData.contactMethod !== ContactMethods.EMAIL && (
        <>
          <ContactMethodTitle>
            {t('contacts.contact_section.contact_period.question')}
          </ContactMethodTitle>
          <FlexRow>
            {CONTACT_SCHEDULES.map((item) => (
              <RadioInput
                id={item.id}
                name="contactSchedule"
                onChange={(e) =>
                  setFormData({ ...formData, contactSchedule: e.target.id })
                }
                key={item.id}
                checked={item.id === formData.contactSchedule}
              >
                <ContactMethodLabel>{t(item.label)}</ContactMethodLabel>
              </RadioInput>
            ))}
          </FlexRow>
        </>
      )}
      <Grid>
        <CheckboxInput id="policy" onChange={handlePrivacyPolicyCheckbox}>
          <PrivacyCheckboxLabel>
            <Trans i18nKey="contacts.contact_section.privacy_policy_check">
              Text
              <a
                href={t('ss.home.footer.legal.privacy_policy.link')}
                target="_blank"
              >
                Link
              </a>
            </Trans>
          </PrivacyCheckboxLabel>
        </CheckboxInput>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Button type="submit">{t('contacts.contact_section.send')}</Button>
        )}
      </Grid>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {formSuccessMessage && (
        <FormSuccessMessage>{formSuccessMessage}</FormSuccessMessage>
      )}
    </StyledForm>
  );
};

export default Form;

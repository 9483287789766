import React, { useEffect, useState } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import Box from 'components/Box';
import fetchPosts from 'services/fetchPosts';

import { PostType } from 'utils/constants/PostType';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchBar from 'components/SearchBar';

import { useI18next } from 'gatsby-plugin-react-i18next';
import Dropdown from 'components/Dropdown';
import { breakpoints } from 'theme';
import { filterByLanguageTag } from 'utils/constants/ContentfulLanguageTags';

import NoResultsImage from 'images/noResultsLupa.svg';

const Container = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
    align-items: flex-start;
  }
`;
const NoResultSection = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.brandPalette.primary};
  border: ${({ theme }) => `1px solid ${theme.brandPalette.primary}`};
  color: ${({ theme }) => theme.neutralPalette.white};

  padding: 12px 24px;
  border-radius: 8px;
  margin-bottom: 172px;

  font-size: ${rem(16)};
  font-weight: ${(props) => props.theme.fontWeights.bold};

  transition: all 150ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.brandPalette.primary};
    color: ${({ theme }) => theme.brandPalette.primary};
    background-color: transparent;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 349px);
  column-gap: 16px;
  row-gap: 24px;
  margin-top: 64px;
  margin-bottom: 56px;

  @media (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    width: 100%;
  }
`;

const Flex = styled.div`
  margin-top: 88px;
  width: 100%;
  max-width: 1080px;
  display: flex;
  gap: 16px;
  align-items: stretch;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    margin-top: 40px;
  }
`;

const Header = styled.h2`
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.neutralPalette.neutral6};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const MagnifyerIcon = styled.img`
  margin: 150px 0 32px 0;
`;

const Subtitle = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

const MessageTitle = styled.h5`
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(24)};
  line-height: 34px;
  margin: 32px 0 0 0;

  @media (max-width: ${breakpoints.tablet}) {
    line-height: ${rem(25)};
    margin: 16px 0 10px 0;
  }
`;
const MessageBody = styled.h6`
  color: ${(props) => props.theme.neutralPalette.neutral6};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(20)};
  margin: 8px 0 600px 0;

  @media (max-width: ${breakpoints.tablet}) {
    line-height: ${rem(25)};
    margin: 16px 0 240px 0;
  }
`;

const ErrorMessage = styled.p`
  color: ${(props) => props.theme.semanticPalette.error.base};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  margin: 16px 0;
`;

const Message = styled.p`
  color: ${(props) => props.theme.brandPalette.dark};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  margin: 88px 0 16px 0;
`;

const CardContainer = styled.div`
  text-align: left;

  display: flex;
  max-width: 349px;
  flex-direction: column;
  gap: 8px;

  padding: 24px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.neutralPalette.white};

  box-shadow: rgb(46 41 51 / 8%) 0px 1px 2px, rgb(71 63 79 / 8%) 0px 2px 4px;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
  }
`;

const CityName = styled.h3`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.dark};
`;

const AddressLine = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
`;

const ContactNumber = styled.strong`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
`;

const GoogleMapsLink = styled.a`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.primary} !important;
  margin-top: 16px;

  transition: all 150ms ease-in-out;

  &:hover {
    color: ${(props) => props.theme.brandPalette.secondary} !important;
  }
`;

interface CardProps {
  element: {
    city: string;
    streetName: string;
    houseNumber: string;
    postCode: string;
    phoneNumber: string;
    googleMapsLink: string;
  };
}

const AddressCard = ({ element }: CardProps) => {
  const {
    city,
    streetName,
    houseNumber,
    postCode,
    phoneNumber,
    googleMapsLink,
  } = element;

  const { t } = useI18next();

  return (
    <CardContainer>
      <CityName>{city}</CityName>
      <div>
        <AddressLine>{streetName}</AddressLine>
        <AddressLine>{houseNumber}</AddressLine>
        <AddressLine>{postCode}</AddressLine>
      </div>
      <ContactNumber>{phoneNumber}</ContactNumber>
      <GoogleMapsLink href={googleMapsLink} target="_blank">
        {t('contacts.form.offices.google_maps')}
      </GoogleMapsLink>
    </CardContainer>
  );
};

const OfficesList = () => {
  const [officesList, setOfficesList] = useState<any>([]);
  const [dropdownCities, setDropdownCities] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [numberOfEntriesToShow, setNumberOfEntriesToShow] = useState(9);
  const [searchBarFilter, setSearchBarFilter] = useState('');
  const [dropdownFilter, setDropdownFilter] = useState('');

  const { t, language } = useI18next();

  const shouldHideButton = numberOfEntriesToShow >= officesList.length;

  const loadMoreEntries = () => {
    setNumberOfEntriesToShow((prevState) => prevState + 9);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchOfficesList = async () => {
      const res = await fetchPosts({
        contentType: PostType.OFFICES,
        order: 'fields.city',
      });

      const filteredResponse = filterByLanguageTag({
        contentfulResponse: res,
        language,
      });

      setOfficesList(filteredResponse);
      setDropdownCities(
        filteredResponse.map((item) => {
          return item.fields.city;
        }),
      );
    };

    try {
      setIsLoading(true);

      if (isMounted) {
        fetchOfficesList();
        setErrorMessage(null);
      }
    } catch {
      if (isMounted) {
        setErrorMessage('Something went wrong, please try again.');
      }
    } finally {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, [language]);

  if (isLoading) {
    return (
      <Box>
        <Container>
          <LoadingSpinner />
        </Container>
      </Box>
    );
  }

  if (errorMessage !== null) {
    return (
      <Box>
        <Container>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Container>
      </Box>
    );
  }
  const filteredOffices = officesList
    .filter((item: any) =>
      item.fields.city.toLowerCase().includes(dropdownFilter.toLowerCase()),
    )
    .filter((item: any) =>
      item.fields.city.toLowerCase().includes(searchBarFilter.toLowerCase()),
    )
    .slice(0, numberOfEntriesToShow);

  const noResults = filteredOffices.length === 0;

  if (noResults && officesList.length > 0) {
    return (
      <Box>
        <Container>
          <Header>{t('contacts.form.offices.title')}</Header>
          <Subtitle>{t('contacts.form.offices.subtitle')}</Subtitle>
          <Flex>
            <SearchBar onChange={setSearchBarFilter} />
            <Dropdown
              placeholder={t('contacts.form.offices.city')}
              dropdownOptions={dropdownCities}
              onChange={setDropdownFilter}
            />
          </Flex>
          <NoResultSection>
            <MagnifyerIcon src={NoResultsImage} alt="magnifyer glass icon" />
            <MessageTitle>{t('contact_no.results_title')}</MessageTitle>
            <MessageBody>{t('contact_no.results_body')}</MessageBody>
          </NoResultSection>
        </Container>
      </Box>
    );
  }

  if (noResults) {
    return (
      <Box>
        <Container>
          <Header>{t('contacts.form.offices.title')}</Header>
          <Subtitle>{t('contacts.form.offices.subtitle')}</Subtitle>
          <Message>{t('contacts.form.offices.empty_state')}</Message>
        </Container>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Container>
          <Header>{t('contacts.form.offices.title')}</Header>
          <Subtitle>{t('contacts.form.offices.subtitle')}</Subtitle>
          <Flex>
            <SearchBar onChange={setSearchBarFilter} />
            <Dropdown
              placeholder={t('contacts.form.offices.city')}
              dropdownOptions={dropdownCities}
              onChange={setDropdownFilter}
            />
          </Flex>
          <Grid>
            {officesList
              .filter((item: any) =>
                item.fields.city
                  .toLowerCase()
                  .includes(dropdownFilter.toLowerCase()),
              )
              .filter((item: any) =>
                item.fields.city
                  .toLowerCase()
                  .includes(searchBarFilter.toLowerCase()),
              )
              .slice(0, numberOfEntriesToShow)
              .map((item: any) => (
                <AddressCard
                  element={item.fields}
                  key={item.fields.googleMapsLink}
                />
              ))}
          </Grid>
          {!shouldHideButton && (
            <Button onClick={loadMoreEntries}>
              {t('contacts.form.offices.load_more')}
            </Button>
          )}
        </Container>
      </Box>
    </>
  );
};

export default OfficesList;

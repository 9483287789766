import React from 'react';
import Box from 'components/Box';
import styled from 'styled-components';
import { rem } from 'polished';

import iconEmail from 'images/icon-email.png';
import iconLocation from 'images/icon-location-pin.png';
import iconPhone from 'images/icon-phone.png';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { breakpoints } from 'theme';

const Flex = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 80px;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    gap: 32px;
    width: 100%;
    margin-bottom: 40px;
  }
`;

const Section = styled.section`
  text-align: center;
  background-color: ${(props) => props.theme.brandPalette.dark};

  @media (max-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`;

const SectionTitle = styled.h4`
  text-transform: uppercase;
  color: ${(props) => props.theme.neutralPalette.white};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  font-weight: ${(props) => props.theme.fontWeights.regular};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const SectionSubtitle = styled.h3`
  color: ${(props) => props.theme.neutralPalette.white};
  font-size: ${rem(32)};
  line-height: ${rem(46)};
  font-weight: ${(props) => props.theme.fontWeights.semibold};

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(28)};
    line-height: ${rem(32)};
  }
`;

const CardContainer = styled.div`
  padding: 24px;
  background-color: ${(props) => props.theme.neutralPalette.white};
  border-radius: 8px;
  text-align: start;
  width: 349px;
  height: 271px;

  display: flex;
  flex-direction: column;

  box-shadow: rgba(2, 196, 178, 0.3) 0px 2px 8px 0px;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: #02c4b2 1.95px 1.95px 2.6px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    height: auto;
  }
`;

const CardIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  user-select: none;
`;

const CardTitle = styled.strong`
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  font-size: ${rem(20)};
  line-height: ${rem(32)};
  color: ${(props) => props.theme.brandPalette.dark};
  margin-bottom: 8px;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

const CardDescription = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;
  margin-bottom: 24px;
`;

const CardDetailsBold = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${(props) => props.theme.brandPalette.primary};

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(14)};
    line-height: ${rem(21)};
  }
`;

const CardDetailsRegular = styled.p`
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-size: ${rem(14)};
  line-height: ${rem(21)};
  color: ${(props) => props.theme.brandPalette.dark};
  opacity: 0.8;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${rem(14)};
    line-height: ${rem(21)};
  }
`;

interface CardProps {
  element: {
    icon: any;
    title: string;
    description: string;
    details: JSX.Element[];
  };
}

const Card = ({ element }: CardProps) => {
  const { icon, title, description, details } = element;
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardIcon src={icon} alt="" aria-hidden="true" />
      <CardTitle>{t(title)}</CardTitle>
      <CardDescription>{t(description)}</CardDescription>
      {details.map((detail) => detail)}
    </CardContainer>
  );
};

const ContactsList = () => {
  const { t } = useTranslation();

  const CARD_ITEMS = [
    {
      icon: iconEmail,
      title: 'contacts.get_in_touch.contact_card.title',
      description: 'contacts.get_in_touch.contact_card.subtitle',
      details: [
        <CardDetailsBold key={'email1'}>
          {t('contacts.get_in_touch.contact_card.info_email')}
        </CardDetailsBold>,
        <CardDetailsBold key={'email2'}>
          {t('contacts.get_in_touch.contact_card.hr_email')}
        </CardDetailsBold>,
      ],
    },
    {
      icon: iconLocation,
      title: 'contacts.get_in_touch.visit_card.title',
      description: 'contacts.get_in_touch.visit_card.subtitle',
      details: [
        <CardDetailsRegular key={'address'}>
          {t('contacts.get_in_touch.visit_card.address')}
        </CardDetailsRegular>,
      ],
    },
    {
      icon: iconPhone,
      title: 'contacts.get_in_touch.call_card.title',
      description: 'contacts.get_in_touch.call_card.subtitle',
      details: [
        <CardDetailsBold key={'phoneNumber'}>
          {t('contacts.get_in_touch.call_card.phone_number')}
        </CardDetailsBold>,
      ],
    },
  ];

  return (
    <Section>
      <Box>
        <SectionTitle>{t('contacts.get_in_touch.subtitle')}</SectionTitle>
        <SectionSubtitle>{t('contacts.get_in_touch.title')}</SectionSubtitle>
        <Flex>
          {CARD_ITEMS.map((item) => (
            <Card element={item} key={item.title} />
          ))}
        </Flex>
      </Box>
    </Section>
  );
};

export default ContactsList;

const sendContactFormEmail = async (
  formData: ContactFormDTO,
  submissionDestination: string,
) => {
  const body = {
    to: submissionDestination,
    transactional_message_id: 'contact_form',
    identifiers: {
      email: submissionDestination,
    },
    message_data: {
      name: formData.name,
      email: formData.email,
      phone_number: formData.phoneNumber,
      message: formData.contactReason,
      channel: formData.contactMethod,
      time: formData.contactSchedule || 'N/A',
    },
  };

  const res = await fetch('https://api-eu.customer.io/v1/send/email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.GATSBY_CUSTOMER_IO_API_KEY}`,
    },
    body: JSON.stringify(body),
  });

  return res;
};

export default sendContactFormEmail;
